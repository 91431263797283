<template>
  <!-- start calls -->
  <div class="row">
    <div class="col-xxl col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          {{ convo.applicants.length }} Applicants
        </h4>
        <div class="row">
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-success display-4">{{ elegibles }}</span>
            </p>
            <p class="my-0">
              Elegibles
            </p>
          </div>
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-danger display-4">{{ noElegibles }}</span>
            </p>
            <p class="my-0">
              No elegibles
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Applicants by area
        </h4>
        <div class="row d-flex justify-content-between">
          <div
            v-for="area in convo.areas.filter(e => !e.parent_id)"
            :key="area.id"
            class="col-auto text-center d-flex flex-column"
          >
            <p class="my-0">
              <span class="text-info display-4">{{ convo.applicants.filter(e => e.areas.some(r => r.id === area.id)).length }}</span>
            </p>
            <p class="my-0">
              {{ area.code }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Gender
        </h4>
        <div class="row">
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ males }}</span>
            </p>
            <p class="my-0">
              Man
            </p>
          </div>
          <div class="col-6 col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ females }}</span>
            </p>
            <p class="my-0">
              Woman
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Coming from
        </h4>
        <div class="row">
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ comingFromSpanish }}</span>
            </p>
            <p class="my-0">
              Spain
            </p>
          </div>
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ comingFromOthers }}</span>
            </p>
            <p class="my-0">
              Abroad
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Citizenship
        </h4>
        <div class="row">
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ spanish }}</span>
            </p>
            <p class="my-0">
              Spain
            </p>
          </div>
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ others }}</span>
            </p>
            <p class="my-0">
              Other
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end calls -->
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Paginator from '@/views/back/partials/Paginator.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    Paginator,
  },
  props: {
    convo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
    elegibles() {
      return this.convo.applicants.filter(e => e.convo_status.some(r => r.convo_id === this.convo.id && r.status !== 3)).length
    },
    noElegibles() {
      return this.convo.applicants.filter(e => e.convo_status.some(r => r.convo_id === this.convo.id && r.status === 3)).length
    },
    males() {
      return this.convo.applicants.filter(e => e.genre_id === 1).length
    },
    females() {
      return this.convo.applicants.filter(e => e.genre_id === 2).length
    },
    spanish() {
      return this.convo.applicants.filter(e => e.citizenship_id_1 === 21 || e.citizenship_id_1 === 808).length
    },
    others() {
      return this.convo.applicants.filter(e => e.citizenship_id_1 !== 21 && e.citizenship_id_1 !== 808).length
    },
    comingFromSpanish() {
      return this.convo.applicants.filter(e => e.country == 21 || e.country == 808).length
    },
    comingFromOthers() {
      return this.convo.applicants.filter(e => e.country != 21 && e.country != 808).length
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>
