<template>
  <div
    class="col-12"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapseChangeRequests`"
        class="card-header"
      >
        <h3 class="float-start">
          <i
            data-feather="globe"
            class="w-icon-24"
          /> Change requests<span
            v-if="!loading"
            class="badge bg-light-info"
          >{{ auxItems.length }}</span>
        </h3>
      </div>
      <b-collapse
        :id="`collapseChangeRequests`"
        :visible="fields.find(e => e.name == 'Change requests').visibility"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th
                    v-if="loggedUser.roles.includes('super-admin')"
                  >Researcher</th>
                  <th>Date</th>
                  <th>Trip begin date</th>
                  <th>Type</th>
                  <th>Comment</th>
                  <th>Answer</th>
                  <th>Status</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in auxItems"
                  :key="index"
                >
                  <td v-if="loggedUser.roles.includes('super-admin')">
                    {{ item.user ? item.user.user_name : '--' }}
                  </td>
                  <td>{{ item.request_date }}</td>
                  <td>{{ item.begin_date }}</td>
                  <td>{{ item.type }}</td>
                  <td><span v-html="item.comment" /></td>
                  <td><span v-html="item.answer" /></td>
                  <td><span v-html="item.request_status ? item.request_status.status_table : ''" /></td>
                  <td
                    class="text-end"
                  >
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <i data-feather="more-vertical" />
                      </template>
                      <div
                        class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                      >
                        <b-dropdown-item
                          v-if="item.request_status"
                          @click="changeStatus(item)"
                        >
                          <template v-if="item.request_status.status === 'Open'">
                            <i
                              class="me-50"
                              data-feather="archive"
                            /> Archive
                          </template>
                          <template v-else>
                            <i
                              class="me-50"
                              data-feather="book-open"
                            /> Open
                          </template>
                        </b-dropdown-item>
                        <div class="dropdown-divider" />
                        <b-dropdown-item @click="showAnswer(item)">
                          <i
                            class="me-50"
                            data-feather="send"
                          /> Answer
                        </b-dropdown-item>
                        <div class="dropdown-divider" />
                        <b-dropdown-item
                          target="_blank"
                          :href="$router.resolve({ name: item.route, params: { id: item.origenable_id }, query: { actAs: $route.query.actAs } }).href"
                        >
                          <i
                            class="me-50"
                            data-feather="edit-3"
                          /> Edit
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                    <a
                      href=""
                      class="btn btn-icon btn-light-secondary d-block d-sm-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasMenu"
                      aria-controls="offcanvasBottom"
                    ><i
                      data-feather="more-vertical"
                    /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-collapse>
      <ItemInfoSheet :table-id="answerInfoSheet">
        <template #info-sheet-title>
          Answer
        </template>
        <template
          #info-sheet
        >
          <div class="offcanvas-body offcanvas-body--view">
            This action will send an e-mail to the ICREA
            <div class="my-1">
              <label
                for=""
                class="form-label"
              >Message</label>
              <quill-editor
                ref="quillMessage"
                v-model="message"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              v-if="!loading"
              type="button"
              class="btn btn-primary mb-1 d-grid w-100"
              @click="sendAnswer"
            >
              Send
            </button>
            <button
              v-if="loading"
              class="btn btn-primary mb-1 d-grid w-100"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Sending...</span>
            </button>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import ItemInfoSheet from '../../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCollapse,
    ItemInfoSheet,
  },
  data() {
    return {
      userId: this.$route.params.id,
      selectedTrip: null,
      loading: true,
      answerInfoSheet: 999999,
      message: '',
    }
  },
  computed: {
    ...mapGetters({
      auxItems: 'tssManagement/changeRequests',
      loggedUser: 'auth/admin',
      user: 'users/user',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    this.reloadPage()
  },
  methods: {
    async changeStatus(item) {
      await this.$store.dispatch('tssManagement/setStatus', {
        id: item.id,
        data: { type: item.request_status.status === 'Open' ? 'archived' : 'open' },
      }).then(() => {
        this.$toastr.success('', 'Actions applied successfully!')
        this.$store.dispatch('tssManagement/changeRequestFilter', this.filters)
      })
    },
    showAnswer(item) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.answerInfoSheet)
      this.selectedTrip = item
    },
    async reloadPage() {
      this.loading = true
      await this.$store.dispatch('tssManagement/changeRequestFilter', { pagination: false })
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async sendAnswer() {
      if (this.$refs.quillMessage.quill.getLength() - 1 > 0) {
        Vue.swal({
          title: 'This action will send an e-mail to the ICREA',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            this.loading = true
            await this.$store.dispatch('tssManagement/sendAnswer', {
              id: this.selectedTrip.id,
              data: { message: this.message },
            }).then(resp => {
              if (resp.status === 200 || resp.status === '200') {
                this.loading = false
                this.message = ''
                this.selectedTrip = null
                Vue.swal('Answer sent', '', 'success')
                this.$store.dispatch('modals/toggleInfoItemSheet', false)
                this.$store.dispatch('tssManagement/changeRequestFilter', this.filters)
              } else {
                this.loading = false
                Vue.swal('Error sendind request', `${resp.message ? resp.message : ''}`, 'error')
              }
            })
          }
        })
      } else {
        Vue.swal('There is not meesage to send', '', 'warning')
      }
    },
  },
}
</script>

<style>

</style>
